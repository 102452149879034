import "controllers";
import "stylesheets/home.scss";
import * as Sentry from "@sentry/browser";
import "particles.js";

Sentry.init({
  dsn: "https://e7cae43300234c36b84c6bfdcf20c2cb@o1036852.ingest.sentry.io/6089350",
});

import Rails from "@rails/ujs";
Rails.start();

particlesJS.load("dots", "/particles.json");

particlesJS.load("dots-main", "/particles-main.json");
